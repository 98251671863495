<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-sheet rounded="lg">
          <v-list color="transparent">
            <v-list-item v-for="n in services.length" :key="n" link>
              <v-list-item-content>
                <v-list-item-title>
                  List Item {{ services[n - 1] }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <v-list-item color="grey lighten-4">
              <v-list-item-content> </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>

      <v-col cols="9">
        <v-sheet min-height="70vh" rounded="lg">
          <h1 style="style='position: relative; text-align: center;">
            {{ Service_title }}
          </h1>
          <!-- video of service -->
          <iframe
            style="display: block; margin-left: auto; margin-right: auto; transform:scale(.5); margin-top:-100px; margin-bottom:-100px"
            width="837"
            height="471"
            :src="video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div v-html="txt"></div>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- contact us form -->
    <div class="graph-container">
      <div class="row">
        <div class="column-2">
          <ContactInfo />
        </div>
        <div class="column-3">
          <ContactForm />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import ContactForm from "../components/ContactForm.vue";
import ContactInfo from "../components/ContactInfo.vue";
import "../assets/style/style.css";
export default {
  name: "Service",
  components: {
    ContactForm,
    ContactInfo,
  },
  props: {
    Service_title: String,
    services: Array,
    video: String,
    txt: String,
  },
  data: () => ({
    links: ["Dashboard", "Messages", "Profile", "Updates"],
  }),
};
</script>
