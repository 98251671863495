<template>
  <div class="Topografia">
    <Service 
    Service_title='Topografía' 
    v-bind:services="['a', 'b', 'c', 'd']" 
    video="https://www.youtube.com/embed/k2t6s8z7qGM"
    txt="
    <div>
      <div style='position: relative; text-align: center;'>
        <br>Lorem, ipsum dolor sit amet consectetur adipisicing 
        elit. Suscipit odio impedit magni optio est aliquam sint, 
        harum porro mollitia veritatis quod qui quasi deserunt! 
        Libero, culpa? Molestiae et ut dolorum.Lorem, ipsum dolor 
        sit amet consectetur adipisicing elit. Suscipit odio impedit 
        magni optio est aliquam sint, harum porro mollitia veritatis 
        quod qui quasi deserunt! Libero, culpa? Molestiae et ut dolorum.
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
        Suscipit odio impedit magni optio est aliquam sint, 
        harum porro mollitia veritatis quod qui quasi deserunt! 
        Libero, culpa? Molestiae et ut dolorum.Lorem, ipsum dolor 
        sit amet consectetur adipisicing elit. Suscipit odio impedit 
        magni optio est aliquam sint, harum porro mollitia veritatis 
        quod qui quasi deserunt! Libero, culpa? Molestiae et ut dolorum.
      </div>
    </div>"
    />
  </div>
</template>
<script>
import Service from '../components/Service.vue'
export default {
  components: {
    Service,
  }
}
</script>